import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { Colors, Gutter } from '../../utils/styles'

const StyledLink = styled(Link)({
  color: Colors.PRIMARY,
  fontSize: 20,
  fontWeight: 700,
  textDecoration: 'none',
  padding: `${Gutter.HALF} 0`,
  ':hover': {},
})

const MobileNavLink = props => (
  <StyledLink to={props.to} id={props.id}>
    {props.label}
  </StyledLink>
)

export default MobileNavLink

MobileNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
