import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import Image from '../Image'
import MobileMenuButton from '../PrimaryNavBar/MobileMenuButton'
import MobileNavLinks from './MobileNavLinks'
import {
  Colors, ZIndexes, Gutter, MediaQueries
} from '../../utils/styles'
import assets from '../../content/assets'

const MobileNavMenuContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.WHITE};
  z-index: ${ZIndexes.NAV_MOBILE_MENU};
`

const MobileNavMenuTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${Gutter.HALF};
`

const MobileMenuButtonContainer = styled.div({
  marginTop: 29,
})

const LogoLink = styled(Link)`
  padding-top: 19px;
  padding-left: 17px;
  @media (max-width: ${MediaQueries.MOBILE}) {
    padding-left: 0;
  }
`

const MobileNavMenu = ({ toggleMobileNavMenu, shouldShowMobileNavMenu }) => (
  <MobileNavMenuContainer>
    <MobileNavMenuTopBar>
      <LogoLink to="/" id="primary-nav-bar--home">
        <Image src={assets.logos.logoFullMiniTextColor} alt="Pulse Digital Logo" height="100%" />
      </LogoLink>
      <MobileMenuButtonContainer>
        <MobileMenuButton
          clickHandler={toggleMobileNavMenu}
          shouldShowMobileNavMenu={shouldShowMobileNavMenu}
        />
      </MobileMenuButtonContainer>
    </MobileNavMenuTopBar>
    <MobileNavLinks />
  </MobileNavMenuContainer>
)

MobileNavMenu.propTypes = {
  toggleMobileNavMenu: PropTypes.func.isRequired,
  shouldShowMobileNavMenu: PropTypes.bool.isRequired,
}

export default MobileNavMenu
