export const navLinkConfig = [ // eslint-disable-line
  {
    link: '/products/payer-quality-of-access',
    label: 'Payer Quality of Access',
    id: 'primary-nav-bar--payer-quality-of-access'
  },
  {
    link: '/products/provider-key-accounts',
    label: 'Provider Key Accounts',
    id: 'primary-nav-bar--provider-key-accounts'
  },
  {
    link: '/products/value-based-models',
    label: 'Value-Based Models',
    id: 'primary-nav-bar--value-based-models'
  },
  {
    link: '/careers',
    label: 'Careers',
    id: 'primary-nav-bar--careers'
  }
]
