/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import PrimaryNavBar from '../PrimaryNavBar'
import Footer from '../Footer'
import MobileNavMenu from '../MobileNavMenu'
import './layoutStyles.css'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldShowMobileNavMenu: false
    }
    this.toggleMobileNavMenu = this.toggleMobileNavMenu.bind(this)
  }

  toggleMobileNavMenu() {
    const { shouldShowMobileNavMenu } = this.state
    this.setState({
      shouldShowMobileNavMenu: !shouldShowMobileNavMenu
    })
  }

  render() {
    const { children } = this.props
    const { shouldShowMobileNavMenu } = this.state

    return (
      <div>
        {shouldShowMobileNavMenu && (
          <MobileNavMenu
            shouldShowMobileNavMenu={shouldShowMobileNavMenu}
            toggleMobileNavMenu={this.toggleMobileNavMenu}
          />
        )}
        <PrimaryNavBar
          shouldShowMobileNavMenu={shouldShowMobileNavMenu}
          toggleMobileNavMenu={this.toggleMobileNavMenu}
        />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
