import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Image from '../Image'

import NavLink from './NavLink'
import DemoButton from './DemoButton'

import {
  Colors, Gutter, Heights, MediaQueries, ZIndexes
} from '../../utils/styles'
import assets from '../../content/assets'
import { navLinkConfig } from './utils'

import MobileMenuButton from './MobileMenuButton'

const NavBarContainer = styled.div({
  background: Colors.WHITE,
  display: 'flex',
  justifyContent: 'space-between',
  height: Heights.PRIMARY_NAV_BAR,
  padding: `0 ${Gutter.HALF}`,
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: ZIndexes.PRIMARY_NAV_BAR,
  '@media (max-width: 520px)': {
    padding: `0 ${Gutter.QUARTER}`,
  },
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    position: 'fixed',
  },
})

const iconOnlyLogoBreakpoint = '360px'

const DesktopLogo = styled.div`
  @media (max-width: ${iconOnlyLogoBreakpoint}) {
    display: none;
  }
`

const IconOnlyLogo = styled.div`
  display: none;
  @media (max-width: ${iconOnlyLogoBreakpoint}) {
    display: inline;
  }
`

const NavContentBlockRight = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  padding: `0 ${Gutter.HALF}`,
})

const DesktopLinksWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 ${Gutter.HALF};
  @media (max-width: ${MediaQueries.TABLET}) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  display: none;
  @media (max-width: ${MediaQueries.TABLET}) {
    display: flex;
  }
`

const NavLinksContainer = styled.div`
  display: flex;
  height: 100%;
  margin-right: ${Gutter.FULL};
`
const NavContentBlock = () => (
  <NavContentBlockRight>
    <DesktopLogo>
      <Link to="/" id="primary-nav-bar--home">
        <Image src={assets.logos.logoFullMiniTextColor} alt="Pulse Digital Logo" height="36px" />
      </Link>
    </DesktopLogo>
    <IconOnlyLogo>
      <Link to="/" id="primary-nav-bar--home">
        <Image src={assets.logos.logoIconColor} alt="Pulse Digital Logo" height="52px" />
      </Link>
    </IconOnlyLogo>
  </NavContentBlockRight>
)

const DesktopLinks = () => (
  <DesktopLinksWrapper>
    <NavLinksContainer>
      {navLinkConfig.map(({ link, label, id }) => (
        <NavLink key={id} to={link} label={label} id={id} />
      ))}
    </NavLinksContainer>
    <DemoButton />
  </DesktopLinksWrapper>
)

const PrimaryNavBar = ({ toggleMobileNavMenu, shouldShowMobileNavMenu }) => (
  <NavBarContainer shouldShowMobileNavMenu={shouldShowMobileNavMenu}>
    <NavContentBlock />
    <DesktopLinks />
    <MobileMenuContainer>
      <MobileMenuButton
        clickHandler={toggleMobileNavMenu}
        shouldShowMobileNavMenu={shouldShowMobileNavMenu}
      />
    </MobileMenuContainer>
  </NavBarContainer>
)

PrimaryNavBar.propTypes = {
  toggleMobileNavMenu: PropTypes.func.isRequired,
  shouldShowMobileNavMenu: PropTypes.bool.isRequired,
}

export default PrimaryNavBar
