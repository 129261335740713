import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors, Gutter, Transitions } from '../../utils/styles'

const LinkContainer = styled.div({})

const HoverBorder = styled.div({
  display: 'flex',
  height: 4,
  marginTop: Gutter.QUARTER,
  transition: Transitions.NORMAL,
  width: 0,
})

const Bar = styled.div(props => ({
  background: props.barColor,
  flex: 'auto',
  width: 'auto',
}))

const StyledLink = styled(Link)({
  color: Colors.WHITE,
  display: 'inline-block !important',
  fontSize: 16,
  fontWeight: 700,
  padding: `${Gutter.QUARTER} 0`,
  textDecoration: 'none',
  width: 'auto',
  ':hover': {
    [HoverBorder]: {
      width: '100%',
    },
  },
})

const FooterLink = props => (
  <LinkContainer>
    <StyledLink to={props.to} id={props.id}>
      {props.label}
      <HoverBorder>
        <Bar barColor={props.barColor} />
        <Bar barColor={transparentize(0.2, props.barColor)} />
        <Bar barColor={transparentize(0.4, props.barColor)} />
        <Bar barColor={transparentize(0.6, props.barColor)} />
        <Bar barColor={transparentize(0.8, props.barColor)} />
      </HoverBorder>
    </StyledLink>
  </LinkContainer>
)

export default FooterLink

FooterLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
  barColor: PropTypes.string,
  id: PropTypes.string,
}

FooterLink.defaultProps = {
  label: 'Footer Link',
  barColor: Colors.WHITE,
  id: 'footer-link',
}
