import React from 'react'
import { Colors } from '../../utils/styles'

const MenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill={Colors.PRIMARY} fillRule="evenodd" transform="translate(0 1.5)">
      <path
        id="hamburger-menu--top-bottom-bars"
        d="M0,0 L24,0 L24,3 L0,3 L0,0 Z M0,18 L24,18 L24,21 L0,21 L0,18 Z"
      />
      <rect id="hamburger-menu--middle-bar-1" width="24" height="3" y="9" />
      <rect id="hamburger-menu--middle-bar-2" width="24" height="3" y="9" />
    </g>
  </svg>
)

export default MenuIcon
