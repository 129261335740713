/* eslint-disable */

// Reusable Product Content
const assets = {
  logos: {
    logoFullWhite:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1542229910/pulse-analytics-marketing/logo/pulse-analytics-full-white.svg',
    logoIconWhite:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1542657774/pulse-analytics-marketing/logo/pulse-analytics-icon-white.svg',
    logoFullMiniTextColor:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1566584447/pulse-analytics-marketing/logo/pulse-logo-mini-text.svg',
    logoIconColor:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560951408/pulse-analytics-marketing/logo/pulse-logo-icon-only-blue.svg',
  },
  images: {
    // Home Images
    homeHeroPoster:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_70,w_1440/v1550763947/pulse-digital/backgrounds/video-backgrounds/pulse-digital-home-hero-background-2_poster.png',
    homeProductScreenHero:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1566588102/pulse-analytics-marketing/product-screens/toolscreen-home-1_2x.png',
    homeProductScreen1:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1551819749/pulse-digital/product-screens/product-screen--home-1__v4_2x.png',
    homeProductScreen2:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1551114525/pulse-digital/product-screens/product-screen--home-2__v3_2x.png',

    // Careers Images
    careersHeroPoster:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_85,w_1440/v1550865941/pulse-digital/backgrounds/video-backgrounds/pulse-digital-careers-hero-background-2_poster.png',
    careersOffice1:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_95,w_1920/v1550868622/pulse-digital/careers/careers--pulse-room-1.jpg',
    careersWhiteboard1:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_95,w_896/v1550868622/pulse-digital/careers/careers--whiteboard-group-1.jpg',
    careersParing1:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_95,w_896/v1550868622/pulse-digital/careers/careers--pairing-1.jpg',
    careersCouchCoding1:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_95,w_896/v1549487538/pulse-digital/careers/careers--team-culture-3.png',

    // Footer
    footerMap:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_95,w_1028/v1551124481/pulse-digital/misc/footer--map_2x.png',

    // Generic Backgrounds
    hiringBlockBackground:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_85,w_1440/v1549574355/pulse-digital/backgrounds/home__hiring-block_2x.png',
    calmPaleBluePanels:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_85,w_1920/v1549553943/pulse-digital/backgrounds/background--calm-pale-lines-1.png',
    fluidBubblesPrimary1:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560883772/pulse-analytics-marketing/backgrounds/fluid-bubbles-primary-01.png',
    fluidBubblesPrimary1Blur:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560888648/pulse-analytics-marketing/backgrounds/fluid-bubbles-primary-01-blur.png',
    fluidBubblesPrimary2:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560888452/pulse-analytics-marketing/backgrounds/fluid-bubbles-primary-02.png',
    fluidBubblesPrimary3:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565276406/pulse-analytics-marketing/backgrounds/fluid-bubbles-primary-3.png',
    fluidBubblesPrimary4:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565372645/pulse-analytics-marketing/backgrounds/fluid-bubbles-primary-04.png',
    fluidBubblesPrimary5:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565372307/pulse-analytics-marketing/backgrounds/fluid-bubbles-primary-05.png',
    noise2:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560886708/pulse-analytics-marketing/backgrounds/noise-2.png',
    noise2Secondary:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560958670/pulse-analytics-marketing/backgrounds/noise-2-secondary.png',
    target1:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560887700/pulse-analytics-marketing/backgrounds/target-1.png',
  },
  icons: {
    // Placeholder icons
    placholderPrimary:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1561147957/pulse-analytics-marketing/icons/placeholder-icon-primary.svg',
    placholderSecondary:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1561147957/pulse-analytics-marketing/icons/placeholder-icon-secondary.svg',

    // Feature Icons
    featureDataAnalysis:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1550778017/pulse-digital/icons/features/graphic-data-analysis-1.svg',
    featureCustomSolution:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1550777775/pulse-digital/icons/features/graphic-customsolution-1.svg',
    featureExport:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1550777903/pulse-digital/icons/features/graphic-exportpowerpoint-1.svg',

    // Tool icons
    toolPayer:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560885939/pulse-analytics-marketing/icons/tool-payer-primary.svg',
    toolProvider:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560885947/pulse-analytics-marketing/icons/tool-provider-primary.svg',
    toolValueBasedModels:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1560885933/pulse-analytics-marketing/icons/tool-pathways-primary.svg',

    // Checkmark icons
    checkmark1Primary:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1561065138/pulse-analytics-marketing/icons/checkmark-1-primary.svg',
    checkmark1Secondary:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1561065138/pulse-analytics-marketing/icons/checkmark-1-secondary.svg',

    // Dashboard Icons
    management1Primary:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1561062182/pulse-analytics-marketing/icons/mgmt-1-primary.svg',
    accounts2Secondary:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1561062182/pulse-analytics-marketing/icons/accounts-2-secondary.svg',
  },
  video: {
    // Home Videos
    homeHeroBackground:
      'https://res.cloudinary.com/pulsedatatools/video/upload/q_80/v1550761191/pulse-digital/backgrounds/video-backgrounds/pulse-digital-home-hero-background-2_compressed.mp4',

    // Careers Videos
    careersHeroBackground:
      'https://res.cloudinary.com/pulsedatatools/video/upload/q_80/v1550865739/pulse-digital/backgrounds/video-backgrounds/pulse-digital-careers-hero-background-1_compressed.mp4',
  },
}

export default assets
