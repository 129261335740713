import React from 'react'
import styled from '@emotion/styled'

import { Colors, Gutter } from '../../utils/styles'
import { navLinkConfig } from '../PrimaryNavBar/utils'
import DemoButton from '../PrimaryNavBar/DemoButton'
import MobileNavLink from './MobileNavLink'

const MainContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${Gutter.FULL}`,
})

const NavLinksContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: Gutter.DOUBLE,
})

const NavLinksLabel = styled.p({
  color: Colors.PRIMARY,
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: 0.4,
  opacity: 0.5,
  paddingBottom: Gutter.HALF,
  textTransform: 'uppercase',
})

const DemoButtonContainer = styled.div({
  marginTop: Gutter.FULL,
})

const MobileNavLinks = () => (
  <MainContainer>
    <NavLinksContainer>
      <NavLinksLabel>Decision Support Tools</NavLinksLabel>
      {navLinkConfig.map(({ link, label, id }) => (
        <MobileNavLink to={link} id={id} label={label} />
      ))}
      <MobileNavLink to="/blog" label="Blog" id="primary-nav-bar--blog" />
    </NavLinksContainer>
    <DemoButtonContainer>
      <DemoButton />
    </DemoButtonContainer>
  </MainContainer>
)

export default MobileNavLinks
