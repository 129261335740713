import React from 'react'

import ButtonLink from '../Buttons'
import { Colors } from '../../utils/styles'

const DemoButton = () => (
  <ButtonLink
    to="/request-demo/"
    buttonColor={Colors.PRIMARY}
    textColor={Colors.WHITE}
    id="button--cta__request-demo"
  >
    Request Demo
  </ButtonLink>
)

export default DemoButton
