/* eslint-disable */
import { assets } from './assets'

// Reusable Tool Content
const toolContent = {
  title: {
    payer: 'Payer Quality of Access',
    valueBasedModels: 'Value-Based Models',
    provider: 'Provider Key Accounts',
    info: 'info@pulseanalyticsapp.com',
    blog: 'Blog',
  },
  description: {
    payer:
      'Develop better strategies to address coverage challenges, as well as focus messages to improve value perception',
    valueBasedModels:
      'Understand how pathways* and alternative payment models (APMs) stand to impact product utilization and sequencing, as well as identify how to best engage key value based model organizations and their influencers. (* pathways only available for oncology brands)',
    provider: 'Identify provider protocols access & field team engagement priorities',
  },
  shortDescription: {
    payer: 'Inform payer access strategy & engagement planning priorities',
    valueBasedModels:
      'Understand sequencing & emerging value drivers for pathways* & alternative payment models',
    provider: 'Identify provider protocols access & field team engagement priorities',
  },
  icon: {
    payer:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565890479/pulse-analytics-marketing/icons/product-icons/icon-product-payer.svg',
    provider:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565890479/pulse-analytics-marketing/icons/product-icons/icon-product-provider.svg',
    valueBasedModels:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565890479/pulse-analytics-marketing/icons/product-icons/icon-product-pathways.svg',
  },
  iconWhite: {
    payer:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565890479/pulse-analytics-marketing/icons/product-icons/icon-product-payer-white.svg',
    provider:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565890479/pulse-analytics-marketing/icons/product-icons/icon-product-provider-white.svg',
    valueBasedModels:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565890479/pulse-analytics-marketing/icons/product-icons/icon-product-pathways-white.svg',
  },
  link: {
    payer: '/products/payer-quality-of-access',
    valueBasedModels: '/products/value-based-models',
    provider: '/products/provider-key-accounts',
    blog: '/blog',
    info: 'mailto:info@pulseanalyticsapp.com',
  },
  heroToolImage: {
    payer:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565644187/pulse-analytics-marketing/product-screens/payer/payer-tool-screen-2_2x.png',
    valueBasedModels:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565872625/pulse-analytics-marketing/product-screens/value-based-models/pathways-tool-screen-1_2x.png',
    provider:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1566830134/pulse-analytics-marketing/product-screens/provider/provider-tool-screen-1_2x.png',
  },
  heroBackground: {
    payer:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_70,w_1440/v1550763947/pulse-digital/backgrounds/video-backgrounds/pulse-digital-home-hero-background-2_poster.png',
    valueBasedModels:
      'https://res.cloudinary.com/pulsedatatools/image/upload/c_scale,q_70,w_1440/v1550763947/pulse-digital/backgrounds/video-backgrounds/pulse-digital-home-hero-background-2_poster.png',
    provider:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1565372307/pulse-analytics-marketing/backgrounds/fluid-bubbles-primary-05.png',
  },
}

export default toolContent
