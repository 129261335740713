import React from 'react'
import styled from '@emotion/styled'

import Section from '../Section'
import { GridContainer, ContentContainer } from '../Grid'
import Image from '../Image'

import { AlphaColors, Colors, Gutter } from '../../utils/styles'
import assets from '../../content/assets'
import toolContent from '../../content/toolContent'

import CopyrightLabel from './CopyrightLabel'
import FooterLink from './FooterLink'

const SectionHeader = styled.div({
  color: AlphaColors.WHITE_40,
  fontSize: 13,
  fontWeight: 700,
  letterSpacing: 0.4,
  marginBottom: Gutter.HALF,
  textTransform: 'uppercase',
})

const LogoPanel = styled(ContentContainer)({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
})

const SectionLinks = styled.div({})

const Footer = () => (
  <Section backgroundColor={Colors.FOOTER_BLACK}>
    <GridContainer flexWrap="wrap">
      <LogoPanel width={[1, 1, 1 / 2]}>
        <Image src={assets.logos.logoFullWhite} alt="Pulse Digital Logo" height="52px" />
        <CopyrightLabel />
      </LogoPanel>
      <ContentContainer width={[1, 1, 1 / 4]}>
        <SectionHeader>Market Access Tools</SectionHeader>
        <SectionLinks>
          <FooterLink label={toolContent.title.payer} to={toolContent.link.payer} />
          <FooterLink
            label={toolContent.title.valueBasedModels}
            to={toolContent.link.valueBasedModels}
          />
          <FooterLink label={toolContent.title.provider} to={toolContent.link.provider} />
        </SectionLinks>
      </ContentContainer>
      <ContentContainer width={[1, 1, 1 / 4]}>
        <SectionHeader>About</SectionHeader>
        <SectionLinks>
          <FooterLink label="Careers" to="/careers" />
          <FooterLink label={toolContent.title.blog} to={toolContent.link.blog} />
        </SectionLinks>
      </ContentContainer>
    </GridContainer>
  </Section>
)

export default Footer
