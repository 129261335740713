import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const ImageElement = styled.img(props => ({
  height: props.height,
  width: props.width,
}))

const Image = props => (
  <ImageElement src={props.src} alt={props.alt} height={props.height} width={props.width} />
)

export default Image

Image.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
}

Image.defaultProps = {
  height: 'auto',
  width: 'auto',
  alt: 'image',
  src:
    // eslint-disable-next-line
    'https://res.cloudinary.com/pulsedatatools/image/upload/v1560958986/pulse-analytics-marketing/icons/placeholder-icon.svg',
}
