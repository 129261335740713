import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Colors, Gutter } from '../../utils/styles'

import MenuIcon from './MenuIcon'

const Container = styled.div({
  alignItems: 'center',
  display: 'flex',
  padding: ` 0 ${Gutter.HALF}`,
  ':hover': {
    cursor: 'pointer',
  }
})

const Label = styled.span({
  color: Colors.PRIMARY,
  fontSize: 12,
  fontWeight: 500,
  marginRight: Gutter.QUARTER,
  textTransform: 'uppercase',
})

const CLOSE_LABEL = 'Close'
const MENU_LABEL = 'Menu'

const MobileMenuButton = ({
  clickHandler,
  shouldShowMobileNavMenu,
}) => { // eslint-disable-line
  return (
    <Container onClick={clickHandler}>
      <Label>{ shouldShowMobileNavMenu ? CLOSE_LABEL : MENU_LABEL }</Label>
      <MenuIcon />
    </Container>
  )
}

MobileMenuButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  shouldShowMobileNavMenu: PropTypes.bool.isRequired,
}

export default MobileMenuButton
