import React from 'react'
import styled from '@emotion/styled'

import { AlphaColors, Gutter, mediaQueryUtility } from '../../utils/styles'

const Label = styled.div({
  color: AlphaColors.WHITE_40,
  fontSize: 13,
  fontWeight: 400,
  [mediaQueryUtility[1]]: {
    padding: `${Gutter.FULL} 0`,
  },
})

const artificialSpace = 4

const CopyrightLabel = () => (
  <Label>
    <span style={{ marginRight: artificialSpace }}>© Copyright</span>
    <span>{new Date().getFullYear()}</span>
    <span style={{ marginLeft: artificialSpace }}> Pulse Analytics, LLC. All Rights Reserved</span>
  </Label>
)

export default CopyrightLabel
