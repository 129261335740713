import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
// import { css } from '@emotion/core'

import {
  AlphaColors, Colors, MediaQueries, Gutter
} from '../../utils/styles'

const borderSize = '2px'

const StyledLink = styled(Link)`
  align-items: center;
  border-bottom: ${borderSize} solid;
  border-bottom-color: transparent;
  color: ${AlphaColors.BLACK_90};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 100%;
  padding: 0 ${Gutter.HALF};
  text-align: center;
  text-decoration: none;
  transition: 300ms ease;
  & :hover {
    color: ${AlphaColors.BLACK_60};
    /* background: ${AlphaColors.WHITE_10}; */
    /* border-bottom-color: ${Colors.WHITE}; */
  }
  @media (max-width: 1025px) {
    font-size: 12px;
    padding: 0 ${Gutter.QUARTER};
  }
  @media (max-width: ${MediaQueries.MOBILE}) {
    font-size: 14px;
    padding: 0 ${Gutter.HALF};
  }
  @media (max-width: 330px) {
    font-size: 12px;
  }
`

const activeStyle = {
  color: Colors.PRIMARY,
  borderBottomColor: Colors.PRIMARY,
}

const NavLink = props => (
  <StyledLink to={props.to} activeStyle={activeStyle} id={props.id}>
    {props.label}
  </StyledLink>
)

export default NavLink

NavLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
}

NavLink.defaultProps = {
  to: '/',
  label: 'Nav Link',
  id: 'primary-nav-bar--nav-link',
}
